import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { Row, Col } from "reactstrap";
import { connect } from 'react-redux'
import { getAboutInfo } from '../../reducers/About/actions'
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";


import welcomeImg from '../../assets/images/about-us-welcome.png';

const AboutUs = ({ aboutInfo, getAboutInfo }) => {

  useEffect(() => {
    getAboutInfo()
  }, [getAboutInfo])

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="about-us-wrapper-top">
        <div className="w-75 about-us-container-top">
          <h1 className='font-weight-bold'>
            {aboutInfo && aboutInfo.title}
          </h1>
          <p className="about-subtitle">
            {aboutInfo && aboutInfo.first_description}
          </p>
        </div>
      </div>
      <div className="about-us-wrapper">
        <div className="about-us-container">
          <div className="w-75 mb-5 pb-5 about-us-container-top">
            <h5>
              {aboutInfo && aboutInfo.second_description}
            </h5>
          </div>
          <Row className='mb-5 about-us-row'>
            {
              aboutInfo &&
              aboutInfo.sections.map((s) => {
                return (
                  <Col className='text-left' sm={12} md={4} lg={3}>
                    <div className='mb-3 about-us-image-container'>
                      <img
                        alt=''
                        className='w-100'
                        src={`${process.env.GATSBY_XELLA_BACKEND}${s.image}`}
                      />
                    </div>
                    <h4 className="h5 font-weight-bold">
                      {s.title}
                    </h4>
                    <p className='mt-3'>
                      {s.description}
                    </p>
                  </Col>
                )
              })
            }
          </Row>
          <hr />
          <Row className='mt-5 py-5'>
            <Col lg={12}>
              <img alt="" src={welcomeImg} />
              <h5 className='mt-3 font-weight-bold'>
                Радваме се, че открихте нашата платформа.<br />
                Успех в строителството!
              </h5>
            </Col>
          </Row>
        </div>
      </div>

    </Layout>
  );
};

const mapStateToProps = state => ({
  aboutInfo: state.aboutInfo.aboutInfo
});

const mapDispatchToProps = {
  getAboutInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
