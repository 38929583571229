export const urlDescription = {
  '/' : 'Търсачка за строителни фирми и архитекти - Building Companion',
  '/about-us' : 'Пътеводител в строителството на еднофамилна къща',
  '/articles' : 'Статии | Building Companion',
  '/building-guide' : 'Ръководство за строителството на еднофамилна къща',
  '/building-request' : 'Запитване към фирма изпълнител за строеж на еднофамилна къща',
  '/contacts' : 'Експертите на КСЕЛА България са на разположение за консултации',
  '/discounts' : 'Ваучер за безплатен палет зидарски материал',
  '/discounts-terms-of-use' : 'Правила за ползване на отстъпки',
  '/FAQ' : 'Често задавани въпроси',
  '/GDPR' : 'Политика за защита на личните данни',
  '/map' : 'Карта',
  '/news' : 'Новни',
  '/new-single' : 'Новина',
  '/presentations': 'Презентации',
  '/projects' : 'Избрани проекти',
  '/service-details' : 'Детайли на услугата',
  '/services' : 'Услуги',
  '/services?service=architects' : 'Намерете архитект/архитектурно бюро',
  '/services?service=builders' : 'Намерете строителна фирма',
  '/services?service=dealers' : 'Намерете дистрибутор',
  '/single-discount' : 'Детайли на отстъпката',
  '/single-project' : 'Детайли на проекта',
  '/terms-and-conditions?terms-and-conditions=0' : 'Правила за ползване на уебсайта',
  '/terms-and-conditions?terms-and-conditions=1' : 'Общи условия за партньори на Building Companion',
  '/terms-and-conditions?terms-and-conditions=2' : 'Общи условия за допълнителни услуги, предоставяни от Building Companion',
  '/videos' : 'Видеа',
  '/walls-solutions-ytong' : 'Решения за стени YTONG',
  '/walls-solutions-silka' : 'Решения за стени SILKA',
}